<template>
  <div class="home pb0">
    <CommonHeader title="委托方钱包" />
    <van-list
      v-model="isLoading"
      :finished="!hasMore"
      :immediate-check="false"
      finished-text="我也是有底线的"
      @load="loadMoreData"
    >
      <div class="my-wallet-view">
        <div class="my-wallet">
          <div class="can-use-wallet flex">
            <div class="flex-one">
              <div class="t">可用余额({{ merchantInfo.fee_type_text }})</div>
              <div class="b">{{ formatPriceNumber(balance, 2) }}</div>
            </div>
          </div>
          <div class="btns item-flex-center">
            <div
              class="btn"
              :style="rightBtnStyle"
              @click="goPageAction('/users/withdrawal?type=2')"
            >提现</div>
          </div>
        </div>
      </div>
      <div class="record">
        <van-sticky :offset-top="88">
          <div class="record-title flex">
            <div class="flex-one l flex align-items-c">
              <div
                class="item"
                :style="{ color: (type == 3 ? colorData.specialColor : ''), fontWeight: (type == 3 ? 500 : '') }"
                @click="changeTypeAction(3)"
              >消费 <div
                  class="bg"
                  :style="{ background: (type == 3 ? colorData.specialColor : '') }"
                ></div>
              </div>
              <div
                class="item"
                :style="{ color: (type == 4 ? colorData.specialColor : ''), fontWeight: (type == 4 ? 500 : '') }"
                @click="changeTypeAction(4)"
              >提现 <div
                  class="bg"
                  :style="{ background: (type == 4 ? colorData.specialColor : '') }"
                ></div>
              </div>
            </div>
            <div
              class="r flex align-items-c"
              @click="editTimeType"
            >
              <div>按时间</div>
              <img
                v-if="timeType == 1"
                src="https://artimg2.artart.cn/image/20220126/b0630c3334671bfba14b349d0c577978.png"
              >
              <img
                v-else-if="timeType == 2"
                src="https://artimg2.artart.cn/image/20220126/026f53456fb0a9db9f72bb97540dca7c.png"
              >
            </div>
          </div>
        </van-sticky>
        <div class="record-list">
          <template
            v-for="(item, index) in list"
            :key="`list${index}`"
          >
            <template v-if="type == 3">
              <div class="item ">
                <div
                  class="flex"
                  @click="clickHistoryOrderAction(item)"
                >
                  <div class="l flex-one">
                    <div class="lt flex align-items-c">
                      <div class="ft14 fw500">{{ item.category_text }}</div>
                      <div class="ml8 color-gray">{{ item.create_time }}</div>
                    </div>
                    <div
                      v-if="item.related_order && item.related_order.orderList && item.related_order.orderList.length == 1"
                      class="lb"
                    >订单号 {{ item.related_order.orderList[0].order_sn }}</div>
                    <div
                      v-if="item.related_order && item.related_order.orderList && item.related_order.orderList.length > 1"
                      class="lb"
                    >共 {{ item.related_order.orderList.length }} 个订单</div>
                  </div>
                  <div class="r" v-if="item.related_order">
                    <div class="hei">-{{ formatPriceNumber(item.amount, 2) }}</div>
                    <div
                      v-if="item.related_order.orderList && item.related_order.orderList.length > 1"
                      class="rb detail"
                    >查看订单</div>
                  </div>
                </div>
                <div
                  class="show-detail-view"
                  v-if="item.related_order && item.related_order.orderList && item.related_order.orderList.length > 1 && item.is_show"
                >
                  <div class="show-detail">
                    <div
                      class="detail-it"
                      v-for="(m, i) in item.related_order.orderList"
                      :key="`list${index}${i}`"
                      @click="goOrderAction(m.order_id)"
                    >
                      <div class="name">订单 {{ m.order_sn }}</div>
                      <div class="price">-{{ formatPriceNumber(m.pay_price, 2) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="type == 4">
              <div class="item">
                <div
                  class="flex"
                  @click="clickExOrderAction(item)"
                >
                  <div class="l flex-one">
                    <div class="lt flex align-items-c">
                      <div class="ft14 fw500">{{ item.extract_title }}</div>
                      <!-- <div class="ml8 color-gray">{{ item.create_time }}</div> -->
                    </div>
                    <div class="lb">{{ item.create_time }}</div>
                  </div>
                  <div class="r">
                    <div class="hei">-{{ formatPriceNumber(item.extract_price, 2) }}</div>
                    <div class="flex align-items-c justify-e">
                      <div
                        v-if="item.status == -1"
                        class="rb err"
                      >拒绝提现</div>
                      <div
                        v-else-if="item.status == 0"
                        class="rb ing"
                      >处理中</div>
                      <div
                        v-else-if="item.status == 1"
                        class="rb finish"
                      >提现成功</div>
                      <div
                        v-if="item.refund.length > 0"
                        class="rb detail"
                      >提现详情</div>
                    </div>
                  </div>
                </div>
                <div
                  class="show-detail-view"
                  v-if="item.refund.length > 0 && item.is_show"
                >
                  <div class="show-detail">
                    <div
                      class="detail-it"
                      v-for="(m, i) in item.refund"
                      :key="`list${index}${i}`"
                    >
                      <div class="name">{{ m.pay_type_text }} <span>{{ m.create_time }}</span></div>
                      <div class="price">{{ formatPriceNumber(m.amount, 2) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, ref, onBeforeMount } from "vue";
import { Sticky, Toast, ImagePreview } from "vant";
import { formatPriceNumber } from "@/utils/util";
import {
  accountInfo,
  rechargeList,
  frozenList,
  extractList,
  historyList,
} from "@/api/user";

export default {
  components: {
    [Sticky.name]: Sticky,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    // 筛选状态 1 充值   2 冻结   3 消费   4 提现
    const type = ref(3);
    // 按时间  1 倒序  2正序
    const timeType = ref(1);

    const balance = ref(""); // 余额
    const frozen_balance = ref(""); // 冻结余额

    const isLoading = ref(false); // 列表加载控制器
    const hasMore = ref(true); // 是否有更多数据
    const queryParams = ref({
      page: 1,
      limit: 10,
      extract_type: 2,
    });
    const list = ref([]); // 列表

    // 获取button样式集合
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 左按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    onBeforeMount(() => {
      accountInfo({ account_type: 2 })
        .then((res) => {
          balance.value = res.data.now_money;
          frozen_balance.value = res.data.frozen_balance;
        })
        .catch((message) => Toast(message));
      isLoading.value = true;
      load();
    });

    const load = () => {
      let action = rechargeList;
      switch (type.value) {
        case 1:
          action = rechargeList;
          break;
        case 2:
          action = frozenList;
          break;
        case 3:
          queryParams.value.type = "pay";
          action = historyList;
          break;
        case 4:
          action = extractList;
          break;
      }
      queryParams.value.order = timeType.value == 2 ? "time_asc" : "time_desc";
      action(queryParams.value)
        .then((res) => {
          res.data.list.forEach((m) => {
            m.is_show = false;
          });
          if (queryParams.value.page == 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }

          if (list.value.length >= res.data.count) {
            hasMore.value = false;
          } else {
            hasMore.value = true;
          }
          isLoading.value = false;
        })
        .catch((message) => {
          Toast(message);
          isLoading.value = false;
        });
    };

    // 选择筛选类型
    const changeTypeAction = (t) => {
      type.value = t;
      list.value = [];
      hasMore.value = true;
      isLoading.value = true;
      queryParams.value.page = 1;
      load();
    };

    // 时间  正序倒序筛选  修改
    const editTimeType = () => {
      timeType.value = timeType.value === 1 ? 2 : 1;
      list.value = [];
      hasMore.value = true;
      isLoading.value = true;
      queryParams.value.page = 1;
      load();
    };

    const loadMoreData = () => {
      // 查看更多
      if (!isLoading.value && hasMore.value) {
        isLoading.value = true;
        queryParams.value.page = queryParams.value.page + 1;
        load();
      }
    };

    const goPageAction = (link) => {
      // 跳转页面
      router.push(link);
    };

    const clickOrderAction = (m) => {
      // 点击记录事件
      if (m.pay_type == "bank_transfer" && m.pay_evidence) {
        ImagePreview([m.pay_evidence]);
      }
    };
    const clickExOrderAction = (m) => {
      // 点击记录事件
      if (m.refund.length > 0) {
        m.is_show = !m.is_show;
      }
    };
    const clickHistoryOrderAction = (m) => {
      // 点击消费记录事件  多个订单展开   单个订单直接跳转
      if (
        m.related_order.orderList &&
        m.related_order.orderList.length == 1
      ) {
        router.push(
          "/order/detail?id=" + m.related_order.orderList[0].order_id
        );
      } else if (
        m.related_order.orderList &&
        m.related_order.orderList.length > 1
      ) {
        m.is_show = !m.is_show;
      }
    };

    const goOrderAction = (id) => {
      // 去订单详情
      router.push("/order/detail?id=" + id);
    };

    const clickFrozenOrderAction = (m) => {
      // 点击冻结列表 跳转
      if (m.frozen_type == "album_bond") {
        router.push("/pages/albumDetail/index?id=" + m.related_info.album_id);
      } else if (m.frozen_type == "frozen_price") {
        router.push(
          "/pages/auctionDetail/index?id=" + m.related_info.product_id
        );
      }
    };
    
    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    return {
      type,
      timeType,
      rightBtnStyle,
      leftBtnStyle,
      colorData,
      formatPriceNumber,
      frozen_balance,
      balance,
      list,
      isLoading,
      hasMore,
      merchantInfo,

      editTimeType,
      changeTypeAction,
      loadMoreData,
      goPageAction,
      clickOrderAction,
      clickExOrderAction,
      clickHistoryOrderAction,
      goOrderAction,
      clickFrozenOrderAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f5f5f5;
}

.my-wallet-view{
  padding-top: 32px;
}
.my-wallet {
  margin: 0 32px 32px 32px;
  background: #fff;
  border-radius: 16px;
  padding-bottom: 64px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);

  .can-use-wallet {
    padding-top: 48px;

    .t,
    .b {
      text-align: center;
    }

    .t {
      color: #666;
      font-size: 24px;
    }

    .b {
      font-size: 42px;
      font-family: NUM;
      margin-top: 8px;
      color: #000;
    }
  }

  .btns {
    margin-top: 48px;

    .btn {
      width: 250px;
      height: 72px;
      margin: 0 16px;
      text-align: center;
      line-height: 72px;
    }
  }
}

.record {
  background: #fff;
  .record-title {
    padding: 0 8px;
    background: #fff;
    .l {
      .item {
        color: #666666;
        font-size: 28px;
        padding: 0 24px;
        min-height: 96px;
        line-height: 96px;
        position: relative;

        .bg {
          position: absolute;
          left: 50%;
          bottom: 18px;
          width: 36px;
          height: 6px;
          border-radius: 4px;
          margin-left: -18px;
        }
      }
    }

    .r {
      padding: 0 24px;
      font-size: 24px;
      font-weight: 500;
      color: #666;

      img {
        width: 32px;
        height: 32px;
        display: block;
      }
    }
  }
  .record-list {
    padding: 0 32px;
    .item {
      min-height: 140px;
      border-top: 2px solid #eeeeee;

      .show-detail-view {
        padding: 20px 0;
        .show-detail {
          background: #f7f7f7;
          border-radius: 8px;
          padding: 8px 20px 24px 20px;

          .detail-it {
            display: flex;
            align-items: center;
            margin-top: 16px;
            font-size: 20px;

            .name {
              flex: 1;
              color: #333;

              span {
                color: #999;
              }
            }

            .price {
              color: #000;
              font-size: 24px;
              font-family: NUM;
            }
          }
        }
      }

      .lt {
        font-size: 24px;
      }

      .l,
      .r {
        padding-top: 32px;
      }

      .lb {
        margin-top: 8px;
        font-size: 20px;
        color: #999;

        &.has-img {
          color: #347ff4;
        }

        &.frozen {
          color: #fb7575;
        }

        div {
          max-width: 430px;
        }

        img {
          width: 32px;
          height: 32px;
          display: block;
        }
      }

      .r {
        font-family: NUM;
        font-size: 36px;
        text-align: right;
        .hong {
          color: #fb7575;
        }
        .hei {
          color: #000000;
        }
        .rb {
          font-size: 20px;
          margin-top: 6px;
          color: #999;

          &.err {
            color: #fb7575;
          }
          &.ing {
            color: #fb7575;
          }
          &.finish {
            color: #00c8a0;
          }
          &.detail {
            color: #347ff4;
            margin-left: 10px;
          }
          &.frozen {
            color: #fb7575;
          }
        }
      }

      &:first-child {
        border-top: none;
      }
    }
  }
}
</style>